<template>
  <div>
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
      <!-- <a-button @click="showModal" class="add-bt" type="primary">新增</a-button> -->
      <a-button class="add-bt" type="primary" @click="$refs.saveDialog.info = {};$refs.saveDialog.isShow = true">新增</a-button>
    </Breadcrumb>
    <a-form
      ref="formRef"
      layout="inline"
      :model="form"
    >
      <a-form-item>
        <a-input v-model="form.name" placeholder="名称" />
      </a-form-item>
      <a-form-item>
        <a-select v-model="form.hot" placeholder="是否热门" style="width:150px;">
          <a-select-option :value=0>否</a-select-option>
          <a-select-option :value=1>是</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary"
                  :loading="isQueryLoading"
                  @click="queryList">查询</a-button>
        <a-button @click="resetForm('form')"
                  style="margin-left:20px;">重置</a-button>
      </a-form-item>
    </a-form>
    <a-table :data-source="list"
             bordered
             :rowKey="record => record.id"
             @change="currentChange"
             :columns="columns"
             :pagination="pagination">
      <span slot="action1" slot-scope="scope">
        <div v-if="scope.hot === 0">否</div>
        <div v-if="scope.hot === 1">是</div>
      </span>
      <span slot="action" slot-scope="text,record">
        <a style="margin-right:20px;" @click="$refs.saveDialog.form = record;$refs.saveDialog.isShow = true">编辑</a>
        <a style="margin-right:20px;" @click='deleteKeyword(record.id)' href="javascript:;">删除</a>
      </span>
    </a-table>
    <AddEditKeyword ref="saveDialog" @save="reloadList"></AddEditKeyword>
  </div>
</template>

<script>
import AddEditKeyword from './addOrEdit'
import Breadcrumb from '@/components/breadcrumb.vue'
export default {
  components: {
    AddEditKeyword, Breadcrumb
  },
  data () {
    return {
      breadList: [
        {
          name: '关键字管理',
          url: ''
        }
      ],
      visible: false,
      isQueryLoading: false,
      form: {
        name: '',
        hot: undefined
      },
      columns: [
        { title: '关键字', ellipsis: true, key: 'name', dataIndex: 'name' },
        { title: '是否热门', ellipsis: true, key: 'hot', scopedSlots: { customRender: 'action1' } },
        { title: '时间', dataIndex: 'gmtCreate', ellipsis: true, key: 'gmtCreate' },
        { title: '操作', key: 'action', scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      total: 0,
      list: [],
      selectedRowKeys: []
    }
  },
  created () {
    this.reloadList()
  },
  methods: {
    deleteKeyword (id) {
      this.$store.dispatch('showConfirm', {
        message: '你确定要删除关键字吗？',
        yes: async _ => {
          this.isLoading = true
          await this.$store.dispatch('http', {
            api: 'delGoodsKeyword',
            query: {
              id: id
            },
            success: () => {
              this.isLoading = false
              this.$store.dispatch('showToast', { message: '删除成功' })
              this.reloadList()
            }
          })
        }
      })
    },
    resetForm (formName) {
      this.form = {}
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
        // this.selectedRowKeys = []
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => { }) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => { }) {
      console.log(this.form)
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'goodsKeywordPage',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      this.list = records
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log(val)
      if (this.pagination.current === val.current) {
        this.pagination.current = 1
      } else {
        this.pagination.current = val.current
      }
      this.pagination.pageSize = val.pageSize
      this.loadList()
    },
    // 选中事件
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .form__ct {
    padding: 10px 0;
  }

  .form__items {
    padding-top: 6px;
  }
}
</style>
