<template>
  <a-modal :width="500" :class="$style.container" v-model="isShow" :title="(form.id ? '编辑' : '新增') + '关键字'"
  @ok="determine" @cancel="cancel" :maskClosable="false">
    <a-form-model ref="form" :rules="rules" :class="$style.form__ct" class="al-form" :label-col="{ style: 'width: 100px' }" :wrapper-col="{ style: 'width: 300px' }" layout="inline" :model="form">
      <a-row type="flex" justify="space-between">
        <a-form-model-item label="关键字:" prop="name" style="margin-bottom:0">
          <a-input v-model="form.name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="是否热门">
            <a-radio-group v-model="form.hot">
                <a-radio :value=0>否</a-radio>
                <a-radio :value=1>是</a-radio>
            </a-radio-group>
        </a-form-model-item>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
// import E from 'wangeditor-antd'
export default {
  data () {
    return {
      loading: false,
      isShow: false, // 是否显示弹窗
      form: {
        name: '',
        hot: 0
      },
      rules: {
        name: [
          { required: true, message: '关键字不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  methods: {
    cancel () {
      this.form = {
        name: '',
        hot: 0
      }
    },
    determine (e) {
      delete this.form.gmtCreate
      delete this.form.gmtModified
      let apii = ''
      if (this.form.id) {
        apii = 'updateGoodsKeyword'
      } else {
        apii = 'addGoodsKeyword'
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          let api = apii
          this.$store.dispatch('http', {
            api,
            params: this.form,
            success: () => {
              this.cancel()
              this.$store.dispatch('showToast', { message: (this.form.id ? '编辑' : '新增') + '成功~' })
              this.isShow = false
              this.$emit('save')
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
